import { styled } from "themes"

export const StyledHeadline = styled.h3`
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
  &::after {
    content: "";
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    left: 0;
    bottom: 0;
  }
`
