import { styled } from "themes"

export const Section = styled.section``

export const Container = styled.div``

export const Map = styled.div`
  margin: -18px;
  > img {
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`

export const VenuesList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: -10px;
  margin-top: -60px;
  overflow-x: auto;
  padding-bottom: 12px;
  ::-webkit-scrollbar {
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f4f4f4;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #afafaf;
  }
`

export const Venue = styled.div`
  padding: 10px;
  flex-basis: 202px;
  flex-shrink: 0;
`

export const VenueCard = styled.div`
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
  padding: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 48px rgb(0, 0, 0, 0.06);
  flex-basis: 192px;
`

export const VenueLogo = styled.div`
  text-align: center;
  margin-bottom: 12px;
  > img {
    width: 44px;
  }
`

export const VenueName = styled.div`
  color: ${({ theme }) => theme.color.softBlack};
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
`

export const VenueInfoList = styled.div`
  display: flex;
  flex-direction: column;
`

export const VenueInfo = styled.div`
  display: flex;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const VenueInfoIcon = styled.div`
  margin-right: 4px;
`

export const VenueInfoText = styled.div`
  color: ${({ theme }) => theme.color.softBlack};
  font-size: 11px;
`

export const Form = styled.form`
  padding: 40px 0;
`

export const Headline = styled.h3`
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.color.softBlack};
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 18px;
  &::after {
    content: "";
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.color.softBlack};
  font-size: 12px;
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -15px;
`

export const FormItem = styled.div`
  flex: 1;
  padding: 0 18px;
  margin-bottom: 12px;
  > * {
    width: 100%;
  }
`

export const FormSelect = styled.select`
  padding: 6px 15px;
  border: 1px solid #d9deea;
  border-radius: 4px;
  outline: none;
  color: ${({ theme }) => theme.color.softBlack};
  cursor: pointer;

  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
`

export const FormInput = styled.input`
  padding: 6px 15px;
  border: 1px solid #d9deea;
  border-radius: 4px;
  outline: none;
  color: ${({ theme }) => theme.color.softBlack};
`

export const FormTextarea = styled.textarea`
  padding: 6px 15px;
  border: 1px solid #d9deea;
  border-radius: 4px;
  outline: none;
  color: ${({ theme }) => theme.color.softBlack};
`

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
`

export const AcceptPolicy = styled.div`
  display: flex;
  align-items: center;
  > input[type="checkbox"] {
    border: 1px solid #d9deea;
    border-radius: 4px;
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
  > label {
    margin-left: 18px;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.softBlack};
  }
`

export const Submit = styled.div`
  margin-top: 42px;
  text-align: center;
`
