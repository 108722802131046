import React from "react"
import { ConfigProvider } from "antd"
import { WindowDimensionsProvider, GlobalStyle } from "components"
import { ThemeProvider } from "styled-components"
import { theme } from "themes"
import "./../index.css"
import { Section, Container, Logo, Caption, StyledLink } from "./style"
import { Icons } from "components"
import whiteLogo from "../../images/login-logo.svg"
import overlay from "../../images/overlay.png"

const Containers = ({
  children,
  background,
  backgroundName,
}: IBackgroundLayoutProps) => {
  return (
    <ConfigProvider>
      <Section
        style={{
          background: `url(${overlay}), url(${background}) 0% 0% / cover no-repeat`,
        }}
      >
        <Container>
          <Logo>
            <img src={whiteLogo} alt="BF Logo" />
          </Logo>
          {children}
          <StyledLink to="/">Close this window, and return home</StyledLink>
          <Caption>
            <Icons.Camera /> <span>{backgroundName}</span>
          </Caption>
        </Container>
      </Section>
    </ConfigProvider>
  )
}

const BackgroundLayout = (props: IBackgroundLayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <WindowDimensionsProvider>
        <Containers {...props} />
      </WindowDimensionsProvider>
    </ThemeProvider>
  )
}

export default BackgroundLayout
