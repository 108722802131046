import React, { useState } from "react"
import {
  Section,
  Container,
  Logo,
  Form,
  Headline,
  MutedText,
  FormList,
  FormItem,
  Label,
  Input,
  Submit,
  StyledLink,
  FormItemIcon,
  ErrorMessage,
  InputWithIcon,
} from "./style"
import logo from "../../../../images/logo.svg"
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons"
import { useForm, Controller } from "react-hook-form"

interface IFormInput {
  email: string
  password: string
}

const Login = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = (data: IFormInput) => console.log(data)

  return (
    <Section>
      <Container>
        <Logo>
          <img src={logo} alt="BF Logo" />
        </Logo>
        <Form onSubmit={handleSubmit(onSubmit)} id="login">
          <Headline>Sign in</Headline>
          <MutedText>Enter to access your business account</MutedText>
          <FormList>
            <FormItem>
              <Label>Email</Label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <Input type="email" {...field} />}
                rules={{
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Invalid email",
                  },
                }}
              />
              {errors.email && (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              )}
            </FormItem>
            <FormItem>
              <Label>Password</Label>
              <InputWithIcon>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Password is required",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={showPassword ? "text" : "password"}
                      style={{ paddingRight: 20 }}
                    />
                  )}
                />
                <FormItemIcon onClick={() => setShowPassword(prev => !prev)}>
                  {showPassword ? <EyeInvisibleFilled /> : <EyeFilled />}
                </FormItemIcon>
              </InputWithIcon>
              {errors.password && (
                <ErrorMessage>{errors.password.message}</ErrorMessage>
              )}
            </FormItem>
          </FormList>
          <Submit
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
          >
            LOGIN
          </Submit>
          <StyledLink to={"/login/reset-password"}>
            Forgot your password?
          </StyledLink>
        </Form>
      </Container>
    </Section>
  )
}

export default Login
