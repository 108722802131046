import Search from "./Search"
import ChevronLeft from "./ChevronLeft"
import ChevronRight from "./ChevronRight"
import Pin from "./Pin"
import MenuBar from "./MenuBar"
import Facebook from "./Facebook"
import Twitter from "./Twitter"
import Instagram from "./Instagram"
import Camera from "./Camera"

export default {
  Search,
  ChevronLeft,
  ChevronRight,
  Pin,
  MenuBar,
  Facebook,
  Twitter,
  Instagram,
  Camera,
}
