import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 33.586 33.233"
      {...props}
    >
      <g fill="#f6f6f6" stroke="#d3d3d3">
        <rect width={33.586} height={33.233} rx={4} stroke="none" />
        <rect
          x={0.5}
          y={0.5}
          width={32.586}
          height={32.233}
          rx={3.5}
          fill="none"
        />
      </g>
      <path
        d="M20.77 8.308v3.323h-1.661c-.573 0-.831.673-.831 1.246v2.078h2.492v3.323h-2.492v6.647h-3.324v-6.647h-2.492v-3.323h2.492v-3.324a3.323 3.323 0 013.324-3.323z"
        fill="rgba(102,102,102,0.7)"
      />
    </svg>
  )
}

export default SvgComponent
