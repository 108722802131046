import React from "react"
import { css } from "styled-components"
import { Link } from "gatsby"
import { styled } from "themes"
import { GatsbyImage } from "gatsby-plugin-image"

export const Section = styled.section`
  width: 100%;
  height: 100%;
`

export const Container = styled((props: any) => <Link {...props} />)`
  background: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgb(209, 213, 223, 0.5);
  transition: all 0.2s;
  min-height: 460px;
  height: inherit;
  ${props =>
    props.hover &&
    css`
      background: ${({ theme }) => theme.color.primary};
      ${CardBody} {
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      ${Thumbnail} {
        display: none;
      }
      ${PublishDate} {
        display: none;
      }
      ${Title} {
        color: ${({ theme }) => theme.color.white};
      }
      ${Description} {
        color: ${({ theme }) => theme.color.white};
        flex: none;
      }
      ${ReadMore} {
        color: ${({ theme }) => theme.color.white};
        width: 100%;
        font-size: 12px;
        text-transform: uppercase;
        border: 1px solid ${({ theme }) => theme.color.white};
        border-radius: 4px;
        padding: 8px;
        text-align: center;
        font-weight: 400;
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
      }
    `}
`

export const GatsbyImageStyled = styled((props: any) => (
  <GatsbyImage {...props} />
))``

export const Thumbnail = styled.div`
  position: relative;
  ${GatsbyImageStyled} {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 218px;
    object-fit: cover;
    object-position: center;
  }
  > img.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CardBody = styled.div`
  padding: 15px 20px;
  flex: 1;
`

export const PublishDate = styled.div`
  color: #8b8b8b;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 6px;
  position: relative;
  margin-bottom: 16px;
  &::after {
    content: "";
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

export const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: ${({ theme }) => theme.color.softBlack};
  margin-bottom: 15px;
`

export const Description = styled.p`
  color: #6e6e6e;
  font-size: 14px;
  line-height: 22px;
  height: 88px; // 22 * 3 lines
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Action = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
`

export const ReadMore = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-size: 10px;
  line-height: 18px;
  font-weight: bold;
  position: relative;
  padding-bottom: 5px;
  padding-right: 20px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    bottom: 0;
    left: 0;
  }
  &::before {
    content: "";
    border: solid ${({ theme }) => theme.color.primary};
    border-width: 0 2px 2px 0;
    transform: rotate(-45deg);
    display: inline-block;
    padding: 3px;
    position: absolute;
    bottom: -3px;
    right: 0;
  }
`
