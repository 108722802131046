import React from "react"
import { styled } from "themes"
import { Headline as StyledHeadline } from "components"

export const Section = styled.section`
  background: #f6f7fa;
  padding-top: 96px;
  padding-bottom: 0;
`

export const Container = styled.div`
  width: 100%;
  padding: 0 24px;
`

export const Headline = styled((props: any) => <StyledHeadline {...props} />)`
  margin-bottom: 24px;
  padding-bottom: 12px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Description = styled.p`
  flex: 1;
  font-size: 14px;
`

export const AddressList = styled.div`
  flex: 1;
  padding: 14px;
  display: flex;
  flex-wrap: wrap;
`

export const AddressListItem = styled.div`
  display: flex;
  width: 100%;
`

export const AddressContent = styled.div``

export const Logo = styled.div`
  margin-right: 8px;
  > img {
    width: 25px;
  }
`

export const BranchName = styled.h3`
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  margin-bottom: 4px;
`

export const Address = styled.p`
  font-weight: 14px;
  line-height: 22px;
`
