import React from "react"
import Desktop from "./Desktop"
import { ResponsiveLayout } from "components"

const Select = (props: any) => {
  return (
    <ResponsiveLayout
      mobileView={<Desktop {...props} />}
      desktopView={<Desktop {...props} />}
    />
  )
}

export default Select
