import React from "react"
import { Link } from "gatsby"
import { styled } from "themes"

export const Section = styled.section`
  background: #f6f7fa;
`

export const Container = styled.div`
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 0;
  padding-bottom: 0;
`

export const Headline = styled.h3`
  text-align: center;
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 8px;
  position: relative;
  &::after {
    content: "";
    width: 27px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    background: ${({ theme }) => theme.color.primary};
    transform: translate(-50%, 0);
  }
`

export const SubHeadline = styled.p`
  color: ${({ theme }) => theme.color.softBlack};
  font-size: 18px;
  text-align: center;
`

export const List = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
`

export const ListItem = styled.div`
  flex-basis: 268px;
  padding: 16px;
`

export const Action = styled.div`
  height: 245px;
  background: ${({ theme }) => theme.color.white};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: "";
    width: 100%;
    height: 64px;
    position: absolute;
    top: -64px;
    background: ${({ theme }) => theme.color.white};
  }
`

export const ActionButton = styled((props: any) => <Link {...props} />)`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 500;
  padding: 15px 72px;
  cursor: pointer;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`
