import React from "react"
import { styled } from "themes"
import { Headline as StyledHeadline, Markdown } from "components"
import { ReactMarkdownOptions } from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"

export const Section = styled.section``

export const Container = styled.div``

export const GatsbyImageStyled = styled((props: any) => (
  <GatsbyImage {...props} />
))``

export const Thumbnail = styled.div`
  margin: -10px;
  margin-bottom: 20px;
  > ${GatsbyImageStyled} {
    height: 142px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Detail = styled.div``

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d9d9d9;
  justify-content: center;
  padding-left: 18px;
`

export const InfoItem = styled.div`
  display: flex;
  font-size: 14px;
  > label {
    font-weight: bold;
    margin-right: 12px;
  }
`

export const Headline = styled((props: any) => <StyledHeadline {...props} />)``

export const Description = styled((props: ReactMarkdownOptions) => (
  <Markdown {...props} />
))`
  padding: 12px 0;
  margin-bottom: 0;
  > p {
    font-size: 12px;
  }
`

export const Gallery = styled.div`
  padding: 32px 0;
  margin: 0 -10px;
`

export const GalleryList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
`

export const GalleryItem = styled.div`
  width: 50%;
  padding: 4px;
  > ${GatsbyImageStyled} {
    width: 100%;
    height: 134px;
    object-fit: cover;
    object-position: center;
  }
`

export const ShareWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const NavigationItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  > label {
    font-weight: 500;
    font-size: 10px;
    color: ${({ theme }) => theme.color.darkGray1};
    text-transform: uppercase;
  }
  > span {
    font-weight: bold;
    color: ${({ theme }) => theme.color.black};
    display: flex;
    align-items: center;
  }
  &.prev {
    > label {
      text-align: left;
    }
    > span {
      > .arrow {
        width: 0px;
        height: 0px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid ${({ theme }) => theme.color.black};
        margin-right: 10px;
      }
    }
  }
  &.next {
    > label {
      text-align: right;
    }
    > span {
      > .arrow {
        width: 0px;
        height: 0px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid ${({ theme }) => theme.color.black};
        margin-left: 10px;
      }
    }
  }
`
