import { styled } from "themes"

export const Section = styled.section`
  height: 488px;
`

export const Container = styled.div`
  width: 955px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: inherit;
`

export const Headline = styled.h1`
  color: ${({ theme }) => theme.color.white};
  font-size: 38px;
  font-weight: bold;
  position: relative;
  margin-top: 64px;
  &::after {
    content: "";
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`
