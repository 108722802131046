import { useState, useCallback, useEffect } from "react"
import axios from "axios"
import queryString from "query-string"

const SEARCH_API = process.env.GATSBY_API_ENDPOINT + "/search"
const initialState = {
  data: [],
  total: 0,
}

export const useSearch = () => {
  const [data, setData] = useState<any>(initialState)
  const [isFetching, setIsFetching] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [queryObj, setQueryObj] = useState({
    q: "",
    skip: 0,
  })
  const [query, setQuery] = useState("")

  const fetchData = useCallback(() => {
    if (!query) {
      setData(initialState)
      return
    }
    const fetchingData = () => {
      setIsFetching(true)
      const stringtified = queryString.stringify(queryObj)
      axios
        .get(`${SEARCH_API}?${stringtified}`)
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => setIsFetching(false))
    }
    fetchingData()
  }, [queryObj])

  const onSearch = (query: string) => {
    setQuery(query)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    setQueryObj(prev => ({
      ...prev,
      q: query,
      skip: 0,
    }))
    setPage(1)
  }, [query])

  useEffect(() => {
    setQueryObj(prev => ({
      ...prev,
      skip: (page - 1) * pageSize,
    }))
  }, [page])

  return {
    data,
    isFetching,
    page,
    pageSize,
    setPage,
    setPageSize,
    onSearch,
  }
}
