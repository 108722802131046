import React from "react"
import { styled } from "themes"
import { Headline, Markdown } from "components"
import { ReactMarkdownOptions } from "react-markdown"

export const Section = styled.section``

export const StyledHeadline = styled((props: any) => <Headline {...props} />)`
  margin-left: 20px;
  margin-right: 20px;
`

export const Description = styled((props: ReactMarkdownOptions) => (
  <Markdown {...props} />
))`
  padding: 34px;
  margin-bottom: 0;
  max-width: 496px;
  > p {
    font-size: 14px;
  }
`

export const List = styled.div`
  display: flex;
  max-width: 872px;
  margin: 0 auto;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
`

export const ListItem = styled.div`
  flex-basis: 33.33%;
  padding: 16px;
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 64px 0;
  padding-bottom: 32px;
`

export const ShareWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
