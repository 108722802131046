export const HOME = "/"
export const NOT_FOUND = "/404/"
export const BLOG = "/blog/"
export const ABOUT = "/about/"
export const PROFILE = "/profile/"

export default {
  [HOME]: HOME,
  [NOT_FOUND]: NOT_FOUND,
  [BLOG]: BLOG,
  [ABOUT]: ABOUT,
  [PROFILE]: PROFILE,
}
