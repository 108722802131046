import React from "react"
import { Link } from "gatsby"
import { styled } from "themes"
import backgroundPattern from "../../../images/banner-background-pattern.png"

export const Section = styled.section`
  margin-top: 150px;
`

export const Container = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme }) => theme.color.primary};
  background-image: url(${backgroundPattern});
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 24px;
  padding-bottom: 48px;
  z-index: 1;
`

export const Headline = styled.h3`
  font-size: 32px;
  color: ${({ theme }) => theme.color.white};
  line-height: 32px;
  font-weight: bold;
  max-width: 212px;
  position: relative;
  padding-bottom: 22px;
  &::after {
    content: "";
    position: absolute;
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.white};
    bottom: 0;
    left: 0;
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.color.white};
  font-size: 12px;
  margin-bottom: 24px;
`

export const Action = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionButton = styled((props: any) => <Link {...props} />)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.white};
  background: none;
  border: 1px solid ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  max-width: 235px;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.white};
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export const Picture = styled.div`
  margin-top: -150px;
  > img {
    width: 100%;
    height: auto;
  }
`
