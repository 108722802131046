import React from "react"
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import { ResponsiveLayout } from "components"

const Contact = (props: IContactForm) => {
  return (
    <ResponsiveLayout
      mobileView={<Mobile {...props} />}
      desktopView={<Desktop {...props} />}
    />
  )
}

export default Contact
