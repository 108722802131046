import React from "react"
import { Link } from "gatsby"
import { styled } from "themes"
import backgroundPattern from "../../../images/banner-background-pattern.png"

export const Section = styled.section`
  height: 618px;
`

export const Container = styled.div`
  height: 100%;
  position: relative;
`

export const Background = styled.div`
  background: ${({ theme }) => theme.color.primary};
  background-image: url(${backgroundPattern});
  width: 70%;
  height: 100%;
  right: 0;
  z-index: -1;
  position: absolute;
`

export const Content = styled.div`
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
`

export const ContentWrapper = styled.div`
  max-width: 614px;
  width: 100%;
  margin-left: auto;
  margin-right: 134px;
  position: relative;
  z-index: 1;
`

export const Headline = styled.h3`
  font-size: 38px;
  color: ${({ theme }) => theme.color.white};
  line-height: 32px;
  font-weight: bold;
  max-width: 252px;
  position: relative;
  padding-bottom: 22px;
  &::after {
    content: "";
    position: absolute;
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.white};
    bottom: 0;
    left: 0;
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.color.white};
  font-size: 18px;
  margin-bottom: 24px;
`

export const Action = styled.div`
  text-align: right;
`

export const ActionButton = styled((props: any) => <Link {...props} />)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.white};
  background: none;
  border: 1px solid ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  padding: 15px 42px;
  border-radius: 4px;
  margin-right: 16px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.white};
  }
  &:last-child {
    margin-right: 0;
  }
`

export const Picture = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  > img {
    width: 100%;
    height: auto;
  }
`
