import React from "react"
import {
  Container,
  PublishDate,
  Section,
  Thumbnail,
  Title,
  CardBody,
  MarkdownStyled,
} from "./style"

import playButton from "../../../../images/play-button.svg"
import { displayDate } from "helpers"

import { Typography, Badge } from "antd"

const { Paragraph } = Typography

const ArticleSearchResult = ({
  thumbnail,
  date,
  title,
  description,
  slug,
  hasVideo,
}: IArticle) => {
  return (
    <Section>
      <Badge.Ribbon text="News">
        <Container to={slug}>
          <Thumbnail>
            {hasVideo && (
              <img src={playButton} alt="Play button" className="play-button" />
            )}
            <img src={thumbnail} alt={title} />
          </Thumbnail>
          <CardBody>
            <PublishDate>{displayDate(date)}</PublishDate>
            <Title>{title}</Title>
            <Paragraph ellipsis={{ rows: 2 }}>
              <MarkdownStyled>{description}</MarkdownStyled>
            </Paragraph>
          </CardBody>
        </Container>
      </Badge.Ribbon>
    </Section>
  )
}

export default ArticleSearchResult
