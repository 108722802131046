import React from "react"
import { styled } from "themes"
import { Modal } from "antd"

export const StyledModal = styled((props: any) => <Modal {...props} />)`
  && {
    .ant-modal-close {
      display: none;
    }
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
    }
    .ant-modal-footer {
      border-top: none;
      text-align: center;
    }
  }
`

export const Close = styled.div`
  color: ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: underline;
`
