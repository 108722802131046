import { styled } from "themes"

export const Section = styled.section`
  background: ${({ theme }) => theme.color.white};
`

export const Container = styled.div``

export const Content = styled.div`
  width: 100%;
  padding: 0 12px;
  margin-top: -80px;
`

export const Box = styled.div`
  min-height: 100px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 30px 60px rgb(0, 0, 0, 0.03);
  padding: 18px;
  position: relative;
`

export const BreadcrumbWrapper = styled.div`
  margin-bottom: 8px;
`
