import React from "react"
import { Link } from "gatsby"
import { styled } from "themes"

export const Section = styled.section`
  background: #f1f1f1;
`

export const Container = styled.div`
  min-height: 325px;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
`

export const Content = styled.div`
  position: relative;
  z-index: 2;
`

export const Headline = styled.h3`
  font-size: 31px;
  line-height: 34px;
  font-weight: bold;
  position: relative;
  color: ${({ theme }) => theme.color.softBlack};
  padding-bottom: 8px;
  &::after {
    content: "";
    height: 2px;
    width: 28px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

export const Picture = styled.div`
  position: absolute;
  left: 40%;
  bottom: 0;
  z-index: 1;
  width: 60%;
  overflow: hidden;
  > img {
    height: 375px;
  }
`

export const Description = styled.p`
  max-width: 146px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.softBlack};
  margin-bottom: 20px;
`

export const Action = styled.div`
  display: flex;
`

export const Button = styled((props: any) => <Link {...props} />)`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  border: none;
  outline: none;
  padding: 12px;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`

export const Go = styled.div`
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translate(-50%, 0);
`
