import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 19.694 19.682"
      {...props}
    >
      <g fill={props.color || "currentColor"}>
        <path d="M12.233 8.142a4.819 4.819 0 00-1.867.373.385.385 0 00.3.71 4.074 4.074 0 11-2.194 2.2.385.385 0 00-.71-.3 4.847 4.847 0 104.475-2.982z" />
        <path d="M12.232 16.143a3.154 3.154 0 10-3.154-3.149 3.158 3.158 0 003.154 3.149zm0-5.539a2.385 2.385 0 11-2.385 2.39 2.388 2.388 0 012.385-2.39z" />
        <path d="M17.743 6.293h-1.17l-1.343-2.27a.385.385 0 00-.331-.189H9.565a.385.385 0 00-.331.189l-1.343 2.27h-2.2v-.462a1.385 1.385 0 10-2.77 0v.462h-.97A1.953 1.953 0 000 8.247v9.484a1.953 1.953 0 001.951 1.951h15.792a1.953 1.953 0 001.951-1.951V8.247a1.953 1.953 0 00-1.951-1.951zm-7.958-1.69h4.895l1 1.692h-6.9zM3.693 5.834a.616.616 0 111.231 0v.462H3.693zm15.232 11.9a1.183 1.183 0 01-1.182 1.182H1.951a1.183 1.183 0 01-1.182-1.182V8.25a1.183 1.183 0 011.182-1.182h15.792a1.183 1.183 0 011.182 1.182v9.484z" />
        <path d="M12.233 12.239a.751.751 0 01.75.75.385.385 0 00.769 0 1.521 1.521 0 00-1.519-1.519.385.385 0 000 .769zM12.233 2.822a.385.385 0 00.385-.385V.385a.385.385 0 10-.769 0v2.056a.385.385 0 00.384.381zM14.063 2.823a.383.383 0 00.272-.113l1.411-1.411a.385.385 0 00-.544-.544l-1.415 1.411a.385.385 0 00.272.657zM10.13 2.709a.385.385 0 00.544-.544L9.263.754a.385.385 0 00-.544.544zM5.308 9.45h-2a.385.385 0 100 .769h2a.385.385 0 000-.769zM9.077 10.219a.385.385 0 10-.272-.113.388.388 0 00.272.113z" />
      </g>
    </svg>
  )
}

export default SvgComponent
