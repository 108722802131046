import React from "react"
import { styled } from "themes"
import { Button as AntdButton } from "antd"

export const Button = styled((props: any) => <AntdButton {...props} />)`
  && {
    min-width: 166px;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    cursor: pointer;
    height: 32px;
  }
`
