import React from "react"
import { styled } from "themes"
import { Button as AntdButton } from "antd"

export const Button = styled((props: any) => <AntdButton {...props} />)`
  && {
    min-width: 218px;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    cursor: pointer;
  }
`
