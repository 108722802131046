import React from "react"
import {
  Container,
  Section,
  Content,
  ContentWrapper,
  Headline,
  Description,
  Action,
  ActionButton,
  Picture,
} from "./style"

const BannerWithLeftPicture = ({
  headline,
  description,
  actions,
  picture,
}: IBannerWithLeftPicture) => {
  return (
    <Section>
      <Container>
        <Content>
          <Picture>
            <img src={picture} />
          </Picture>
          <ContentWrapper>
            <Headline>{headline}</Headline>
            <Description>{description}</Description>
            <Action>
              {actions.map((a, k) => (
                <ActionButton key={k} to={a.url}>
                  {a.button}
                </ActionButton>
              ))}
            </Action>
          </ContentWrapper>
        </Content>
      </Container>
    </Section>
  )
}

export default BannerWithLeftPicture
