import React, { useRef } from "react"
import { Carousel as AntdCarousel } from "antd"
import { Container, CarouselItem, Next, Previous } from "./style"
import { Icons } from "components"

const Carousel = ({ sliders }: ICarousel) => {
  const carouselRef = useRef<any>()
  return (
    <Container>
      <AntdCarousel
        dots={false}
        ref={carouselRef}
        style={{ position: "relative" }}
      >
        {sliders.map((component: any, index: number) => (
          <CarouselItem key={index}>{component}</CarouselItem>
        ))}
      </AntdCarousel>
      <Previous onClick={() => carouselRef.current.prev()}>
        <Icons.ChevronLeft color={"#fff"} />
      </Previous>
      <Next onClick={() => carouselRef.current.next()}>
        <Icons.ChevronRight color={"#fff"} />
      </Next>
    </Container>
  )
}

Carousel.defaultProps = {
  sliders: [],
}

export default Carousel
