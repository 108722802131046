import React from "react"
import {
  Container,
  PublishDate,
  Section,
  Thumbnail,
  Title,
  CardBody,
  MarkdownStyled,
} from "./style"

import { displayDate } from "helpers"
import { Typography, Badge } from "antd"

const { Paragraph } = Typography

const CaseStudySearchResult = ({
  thumbnail,
  date,
  title,
  description,
  slug,
}: ICaseStudy) => {
  return (
    <Section>
      <Badge.Ribbon color="geekblue" text="Case Study">
        <Container to={slug}>
          <Thumbnail>
            <img src={thumbnail} alt={title} />
          </Thumbnail>
          <CardBody>
            <PublishDate>{displayDate(date)}</PublishDate>
            <Title>{title}</Title>
            <Paragraph ellipsis={{ rows: 2 }}>
              <MarkdownStyled>{description}</MarkdownStyled>
            </Paragraph>
          </CardBody>
        </Container>
      </Badge.Ribbon>
    </Section>
  )
}

export default CaseStudySearchResult
