import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 11.996 20.002"
      {...props}
    >
      <g data-name="Prev Button">
        <path
          data-name="fa-angle-right copy"
          d="M.206 10.462l9.605 9.341a.664.664 0 00.948 0l1.031-1a.617.617 0 000-.921l-8.1-7.877 8.1-7.876a.618.618 0 000-.921l-1.031-1a.66.66 0 00-.948 0L.206 9.548a.618.618 0 000 .921z"
          fill={props.color || "currentColor"}
        />
      </g>
    </svg>
  )
}

export default SvgComponent
