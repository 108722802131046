import React from "react"
import { Link } from "gatsby"
import { styled } from "themes"

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 0;
`
export const Logo = styled.div`
  position: absolute;
  top: 42px;
  left: 42px;
  > img {
    width: 136px;
  }
`

export const Caption = styled.div`
  position: absolute;
  bottom: 22px;
  right: 54px;
  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  > svg {
    font-size: 16px;
    margin-right: 8px;
  }
  > span {
    font-size: 12px;
    display: block;
  }
`

export const StyledLink = styled((props: any) => <Link {...props} />)`
  width: 100%;
  display: block;
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  margin-top: 24px;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.white};
  }
`
