import { styled } from "themes"

export const Section = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 32px 0;
`

export const Text = styled.div`
  margin-right: 22px;
  font-size: 12px;
`

export const List = styled.div`
  display: flex;
`

export const ListItem = styled.div`
  margin-right: 8px;
  font-size: 32px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`
