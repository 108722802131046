import React from "react"
import { StyledBreadcrumb, StyledBreadcrumbItem } from "./style"
import { Link } from "gatsby"

const Breadcrumb = ({ paths, ...rest }: any) => (
  <StyledBreadcrumb {...rest}>
    {paths.map(({ label, path }: any) => (
      <StyledBreadcrumbItem key={path}>
        <Link to={path}>{label}</Link>
      </StyledBreadcrumbItem>
    ))}
  </StyledBreadcrumb>
)

Breadcrumb.defaultProps = {
  paths: [],
}

export default Breadcrumb
