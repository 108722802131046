import React from "react"
import {
  Container,
  Section,
  Headline,
  Description,
  Thumbnail,
  Content,
  GatsbyImageStyled,
} from "./style"
import { SocialShare } from "components"

const Article = ({ title, description, thumbnail, content = "" }: IArticle) => {
  return (
    <Section>
      <Container>
        <Headline>{title}</Headline>
        <Description>{description}</Description>
        <Thumbnail>
          <GatsbyImageStyled image={thumbnail} alt={title} />
        </Thumbnail>
        <Content>{content}</Content>
        <SocialShare />
      </Container>
    </Section>
  )
}

export default Article
