import React from "react"
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import { ReactMarkdownOptions } from "react-markdown"
import { ResponsiveLayout } from "components"

const Markdown = (props: ReactMarkdownOptions) => {
  return (
    <ResponsiveLayout
      mobileView={<Desktop {...props} />}
      desktopView={<Mobile {...props} />}
    />
  )
}

export default Markdown
