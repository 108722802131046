import React, { useEffect, useRef } from "react"
import {
  Section,
  StyledHeadline,
  Description,
  List,
  ListItem,
  PaginationWrapper,
  ShareWrapper,
} from "./style"
import { Cards, Pagination, SocialShare } from "components"
import { useState } from "react"

const ArticlesListPagination = ({
  headline,
  description,
  articles,
}: IArticlesListPagination) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [skip, setSkip] = useState(0)
  const [list, setList] = useState<IArticle[]>([])
  const sectionRef = useRef<any>(null)

  const scrollUp = () =>
    sectionRef.current.scrollIntoView({ behavior: "smooth" })

  useEffect(() => {
    setSkip((currentPage - 1) * pageSize)
  }, [currentPage, pageSize])

  useEffect(() => {
    setList(articles.slice(skip, pageSize * currentPage))
  }, [skip])

  const handleChange = (page: number, pageSize?: number) => {
    scrollUp()
    setCurrentPage(page)
    if (pageSize != null) {
      setPageSize(pageSize)
    }
  }

  const total = articles.length

  return (
    <Section ref={sectionRef}>
      <StyledHeadline>{headline}</StyledHeadline>
      <Description>{description}</Description>
      <List>
        {list.map((article, k) => (
          <ListItem key={k}>
            <Cards.Article {...article} />
          </ListItem>
        ))}
      </List>
      {total > pageSize && (
        <PaginationWrapper>
          <Pagination
            defaultCurrent={1}
            total={total}
            defaultPageSize={9}
            onChange={handleChange}
            showSizeChanger={false}
          />
        </PaginationWrapper>
      )}
      <ShareWrapper>
        <SocialShare />
      </ShareWrapper>
    </Section>
  )
}

export default ArticlesListPagination
