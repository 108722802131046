import React from "react"
import { styled } from "themes"
import {
  Headline as StyledHeadline,
  Input as StyledInput,
  Buttons,
} from "components"
import { Link } from "gatsby"

export const Section = styled.section``

export const Container = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: 6px;
  border-radius: 36px;
  min-width: 350px;
  width: 100%;
`

export const Logo = styled.div``

export const Form = styled.form`
  padding: 24px 48px;
`

export const Headline = styled((props: any) => <StyledHeadline {...props} />)``

export const MutedText = styled.p`
  color: #7c7c7c;
  margin-bottom: 32px;
`

export const FormList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

export const FormItem = styled.div`
  margin-bottom: 24px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
`

export const Label = styled.label`
  margin-bottom: 4px;
  display: block;
  color: ${({ theme }) => theme.color.darkGray1};
`

export const FormItemIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.color.darkGray1};
  font-size: 18px;
  user-select: none;
`

export const Input = styled((props: any) => <StyledInput {...props} />)`
  && {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.color.darkGray1};
    border-radius: 0;
    outline: none;
    box-shadow: none;
    padding-left: 0;
    font-weight: bold;
  }
`

export const Submit = styled((props: any) => <Buttons.Primary {...props} />)`
  && {
    width: 100%;
  }
`

export const StyledLink = styled((props: any) => <Link {...props} />)`
  text-align: center;
  padding: 0 12px;
  margin-top: 12px;
  width: 100%;
  display: block;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`

export const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 10px;
  margin-top: 8px;
`

export const InputWithIcon = styled.div`
  position: relative;
`
