import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Next Button"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 11.996 20.001"
      {...props}
    >
      <path
        d="M11.79 9.54L2.19.2a.662.662 0 00-.948 0l-1.031 1a.618.618 0 000 .921l8.1 7.876-8.1 7.876a.619.619 0 000 .923l1.031 1a.663.663 0 00.948 0l9.6-9.339a.619.619 0 000-.922z"
        fill={props.color || "currentColor"}
      />
    </svg>
  )
}

export default SvgComponent
