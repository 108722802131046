import React from "react"
import { StyledSelect, StyledOption } from "./style"

const Select = ({ options, ...rest }: any) => {
  return (
    <StyledSelect {...rest}>
      {options.map(({ label, value }: any) => (
        <StyledOption value={value} key={value}>
          {label}
        </StyledOption>
      ))}
    </StyledSelect>
  )
}

Select.defaultProps = {
  options: [],
}

export default Select
