import React from "react"
import Interior01 from "./Interior01"
import Interior02 from "./Interior02"

const CaseStudy = ({ layout, ...props }: ICaseStudy) => {
  switch (layout) {
    case 1:
      return <Interior01 {...props} />
    case 2:
      return <Interior02 {...props} />
    default:
      return <Interior01 {...props} />
  }
}

export default CaseStudy
