import React from "react"
import {
  Section,
  Container,
  Map,
  VenuesList,
  Venue,
  VenueCard,
  VenueLogo,
  VenueName,
  VenueInfoList,
  VenueInfo,
  VenueInfoIcon,
  VenueInfoText,
  Form,
  Headline,
  Description,
  FormContent,
  FormRow,
  FormItem,
  // FormSelect,
  FormInput,
  // FormTextarea,
  FormFooter,
  AcceptPolicy,
} from "./style"
import logo from "../../../../images/logo-bf.svg"
import { Icons, Buttons, Select, Input, TextArea } from "components"

const Contact = ({
  venues,
  headline,
  description,
  label,
  options,
  policyText,
  submitText,
  mapImage,
}: IContactForm) => {
  return (
    <Section>
      <Container>
        <Map>
          <img src={mapImage} alt="Map" />
        </Map>
        <VenuesList>
          {venues.map(({ name, phone, email, address }, k) => (
            <Venue key={k}>
              <VenueCard>
                <VenueLogo>
                  <img src={logo} alt="Logo" />
                </VenueLogo>
                <VenueName>{name}</VenueName>
                <VenueInfoList>
                  <VenueInfo>
                    <VenueInfoIcon>
                      <Icons.Pin />
                    </VenueInfoIcon>
                    <VenueInfoText>{phone}</VenueInfoText>
                  </VenueInfo>
                  <VenueInfo>
                    <VenueInfoIcon>
                      <Icons.Pin />
                    </VenueInfoIcon>
                    <VenueInfoText>{address}</VenueInfoText>
                  </VenueInfo>
                  <VenueInfo>
                    <VenueInfoIcon>
                      <Icons.Pin />
                    </VenueInfoIcon>
                    <VenueInfoText>{email}</VenueInfoText>
                  </VenueInfo>
                </VenueInfoList>
              </VenueCard>
            </Venue>
          ))}
        </VenuesList>
        <Form>
          <Headline>{headline}</Headline>
          <Description>{description}</Description>
          <FormContent>
            <FormRow>
              <FormItem>
                <Select
                  showSearch
                  placeholder="Select Branch"
                  options={options.branch}
                />
              </FormItem>
              <FormItem>
                <Select
                  showSearch
                  placeholder="Select Department"
                  options={options.department}
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <Input placeholder={label.name} />
              </FormItem>
              <FormItem>
                <Input placeholder={label.email} />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <Input placeholder={label.subject} />
              </FormItem>
              <FormItem />
            </FormRow>
            <FormRow>
              <FormItem>
                <TextArea placeholder={label.message} rows={4} />
              </FormItem>
            </FormRow>
          </FormContent>
          <FormFooter>
            <AcceptPolicy>
              <FormInput type="checkbox" />
              <label htmlFor="terms-and-conditions">{policyText}</label>
            </AcceptPolicy>
            <Buttons.Primary>{submitText}</Buttons.Primary>
          </FormFooter>
        </Form>
      </Container>
    </Section>
  )
}

export default Contact
