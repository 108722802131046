import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

export const Section = styled.section`
  width: 100%;
`

export const Container = styled.div``

export const Content = styled.div`
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const ActionWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 25px;
`

export const ActionContent = styled.div`
  display: flex;
  align-items: center;
  height: 200px;
`

export const Logo = styled.div`
  flex-basis: 50px;
  padding-right: 16px;
  > img {
    width: 50px;
  }
`

export const Headline = styled.h3`
  color: ${({ theme }) => theme.color.white};
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;
  width: 100%;
`

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -16px;
  margin-bottom: 12px;
`

export const ActionBtnItem = styled.div`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const ActionButton = styled((props: any) => <Link {...props} />)`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 12px 70px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`

export const TestimonialBox = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: 26px 44px 56px 30px;
  border-radius: 8px;
  box-shadow: 0px 1px 73px #1a282828;
  margin: 0 20px;
  margin-top: -32px;
`

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 14px;
`

export const Description = styled.p`
  color: ${({ theme }) => theme.color.softBlack};
  font-size: 14px;
  position: relative;
  padding-bottom: 20px;
  &::after {
    content: "";
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

export const Name = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.primary};
  font-weight: bold;
`

export const MutedText = styled.div`
  color: #9b9b9b;
  font-size: 10px;
`

export const Navigation = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;
`

const NavigationBtn = styled((props: any) => <div {...props} />)`
  color: #1b1a1a;
  font-size: 14px;
  font-weight: bold;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  position: relative;
  padding-bottom: 4px;
  user-select: none;
  transition: all 0.2s;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #1b1a1a;
    bottom: 0;
  }
  ${props =>
    props.active &&
    css`
      color: ${({ theme }) => theme.color.primary};
      &::before {
        content: "";
        border: solid ${({ theme }) => theme.color.primary};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        position: absolute;
        bottom: -3px;
      }
      &::after {
        background: ${({ theme }) => theme.color.primary};
      }
    `}
`

export const Previous = styled(NavigationBtn)`
  text-align: right;
  padding-left: 20px;
  &::after {
    right: 0;
  }
  ${props =>
    props.active &&
    css`
      &::before {
        transform: rotate(135deg);
        left: 0;
      }
    `}
`

export const Next = styled(NavigationBtn)`
  margin-left: 18px;
  padding-right: 20px;
  text-align: left;
  &::after {
    left: 0;
  }
  ${props =>
    props.active &&
    css`
      &::before {
        transform: rotate(-45deg);
        right: 0;
      }
    `}
`
