import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15.693 15.708"
      {...props}
    >
      <path
        data-name="Search"
        d="M14.926 13.639l-2.854-2.858a6.438 6.438 0 10-1.3 1.3l2.854 2.855a.917.917 0 001.3 0 .921.921 0 000-1.297zm-12.589-6.7a4.592 4.592 0 114.592 4.595 4.6 4.6 0 01-4.592-4.599z"
        fill={props.color || "currentColor"}
        stroke={props.color || "currentColor"}
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default SvgComponent
