import React from "react"
import { Section, Container, Box, Content, BreadcrumbWrapper } from "./style"
import { Hero, Breadcrumb } from "components"

const BoxWrapper = ({
  boxContent,
  heroProps,
  breadcrumbProps,
  children,
}: IBoxWrapper) => {
  return (
    <Section>
      <Container>
        <Hero {...heroProps} />
        <Content>
          <BreadcrumbWrapper>
            <Breadcrumb separator=">" {...breadcrumbProps} />
          </BreadcrumbWrapper>
          <Box>{boxContent}</Box>
        </Content>
        {children}
      </Container>
    </Section>
  )
}

export default BoxWrapper
