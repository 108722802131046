import { Cards } from "components"
import React from "react"
import {
  Container,
  Section,
  Headline,
  SubHeadline,
  List,
  ListItem,
  Action,
  // ActionButton,
} from "./style"

import { Buttons } from "components"

const Articles = ({ headline, subHeadline, articles, actions }: IArticles) => {
  return (
    <Section>
      <Container>
        <Headline>{headline}</Headline>
        <SubHeadline>{subHeadline}</SubHeadline>
        <List>
          {articles.map((article, k) => (
            <ListItem key={k}>
              <Cards.Article {...article} />
            </ListItem>
          ))}
        </List>
      </Container>
      <Action>
        {actions.map((a, k) => (
          <Buttons.Primary key={k} to={a.url}>
            {a.button}
          </Buttons.Primary>
        ))}
      </Action>
    </Section>
  )
}

Articles.defaultProps = {
  headline: "",
  subHeadline: "",
  articles: [],
}

export default Articles
