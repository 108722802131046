import axios from "axios"
import {
  // defaultLang,
  getLocaleAndBasePath,
} from "helpers"
import { get } from "lodash"
export const API_ROOT_URL = process.env.GATSBY_API_URI
export const STATIC_URL = process.env.GATSBY_STATIC_URI
const GATSBY_ACCESS_TOKEN = process.env.GATSBY_ACCESS_TOKEN || ""

const CASE_STUDIES_SEARCH_API =
  process.env.GATSBY_API_ENDPOINT + "/case-studies"

export const getRequest = async (path: string, headers?: any) => {
  const token = window.localStorage.getItem("token") || GATSBY_ACCESS_TOKEN
  const { locale } = getLocaleAndBasePath(get(window, "location.pathname", ""))
  const language: string = window.localStorage.getItem("language") || locale
  const commonHeader = {
    "X-Languages": language,
    "X-Flatten": "true",
  }

  const { data, status } = await axios.get(
    API_ROOT_URL + path + "?timestamp=" + Math.floor(Date.now()),
    {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(headers || {}),
        ...commonHeader,
      },
    }
  )

  if (status !== 200) {
    return
  }

  return data
}

export const getImage = (id: string) => {
  if (id && id.includes("http")) {
    return id
  }

  return `${STATIC_URL}${id}`
}

export const getRelatedCaseStudies = (id: string) => {
  return new Promise(resolve => {
    axios
      .get(`${CASE_STUDIES_SEARCH_API}/related/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        console.error(`Get related case studies failed`, error)
        resolve([])
      })
  })
}
