import baseStyled, { ThemedStyledInterface } from "styled-components"
export const theme = {
  color: {
    primary: "#3AB54A" /* Green */,
    white: "#FFFFFF" /* White */,
    gray1: "#EEEEEE" /* Gray 1 */,
    gray2: "#DDDDDD" /* Gray 2 */,
    mediumGray1: "#BBBBBB" /* Med. Gray 1 */,
    mediumGray2: "#888888" /* Med. Gray 2 */,
    darkGray1: "#777777" /* Dark Gray 1 */,
    darkGray2: "#454545" /* Dark Gray 2 */,
    black: "#000000" /* Black */,
    green: "#3AB54A" /* Green */,
    softGray: "#FA1126" /* Gray Soft Content */,
    bgGray: "#F7F8FB" /* Gray Background */,
    softBlack: "#363940" /* Black titles / Content */,
  },
}
export type Theme = typeof theme
export const styled = baseStyled as ThemedStyledInterface<Theme>
