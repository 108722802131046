import { styled } from "themes"

export const Container = styled.section`
  position: relative;
`

export const CarouselItem = styled.div`
  position: relative;
`

export const NaviagtionButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
`

export const Next = styled(NaviagtionButton)`
  right: 35px;
`

export const Previous = styled(NaviagtionButton)`
  left: 35px;
`
