import { styled } from "themes"

export const Section = styled.section`
  width: 100%;
  background: #191919;
`

export const Container = styled.div`
  width: 100%;
  padding: 32px 20px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Company = styled.div``

export const Logo = styled.div`
  margin-bottom: 2em;
  > img {
    width: 124px;
  }
`

export const Description = styled.p`
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 2em;
`

export const Headline = styled.h3`
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 24px;
`

export const Contact = styled.div``

export const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const Info = styled.li`
  display: flex;
  margin-bottom: 10px;
`

export const InfoIcon = styled.div`
  margin-right: 12px;
  font-size: 1.2rem;
`

export const Venues = styled.div`
  ${InfoIcon} {
    color: #3ab54a;
  }
`

export const InfoText = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #ffffff;
  > a {
    color: #ffffff;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 28px;
  border-top: 1px solid #2e2e2e;
`

export const CopyrightText = styled.div`
  color: #ffffff;
  text-align: center;
`

export const CopyrightList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
`

export const CopyrightItem = styled.li`
  display: inline-block;
  > a {
    color: #ffffff;
    text-decoration: underline;
  }
  &::after {
    content: "-";
    margin: 0 5px;
    color: #ffffff;
  }
  &:last-child {
    &::after {
      content: "";
      margin: 0;
    }
  }
`
