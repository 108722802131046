import React from "react"
import { Section } from "./style"
import header2b from "../../../../images/header2b.png"

const SecondSlide = () => {
  return (
    <Section>
      <img
        src={header2b}
        style={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
          objectFit: "cover",
          objectPosition: "bottom",
        }}
      />
    </Section>
  )
}

export default SecondSlide
