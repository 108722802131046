import React from "react"
import { styled } from "themes"
import { Pagination } from "antd"
import { PaginationProps } from "antd/lib/pagination"

export const StyledPagination = styled((props: PaginationProps) => (
  <Pagination {...props} />
))`
  && {
    background: #f7f7f7;
    border: 1px solid ${({ theme }) => theme.color.white};
    box-shadow: 0 2px 4px rgb(0, 0, 0, 0.16);
    border-radius: 38px;
    padding: 8px;
    > .ant-pagination-item {
      background: none;
      border: none;
      &.ant-pagination-item-active {
        background: ${({ theme }) => theme.color.primary};
        border-radius: 50%;
        > a {
          color: ${({ theme }) => theme.color.white};
          font-weight: bold;
        }
      }
    }
    > .ant-pagination-next,
    .ant-pagination-prev {
      display: none;
    }
  }
`
