import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    *,
    :after,
    :before {
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
        font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
    }

    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.9);
    }
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow  {
        border-top-color: #3AB54A;
        border-left-color: #3AB54A;
    }
    .ant-popover-inner {
        background: #3AB54A;
    }
    .ant-popover-inner-content {
        color: #fff;
        min-width: 154px;
    }
`

export default GlobalStyle
