import React from "react"
import { styled } from "themes"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export const Section = styled.section`
  width: 100%;
  height: 100%;
`

export const Container = styled((props: any) => <Link {...props} />)`
  background: ${({ theme }) => theme.color.white};
  display: flex;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgb(209, 213, 223, 0.5);
  transition: all 0.2s;
  min-height: 84px;
  padding: 5px;
  height: inherit;
`

export const GatsbyImageStyled = styled((props: any) => (
  <GatsbyImage {...props} />
))``

export const Thumbnail = styled.div`
  position: relative;
  ${GatsbyImageStyled} {
    border-radius: 8px;
    padding: 5px;
    width: 102px;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  > img.play-button {
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CardBody = styled.div`
  padding: 15px 10px;
  flex: 1;
`

export const PublishDate = styled.div`
  color: #8b8b8b;
  font-size: 10px;
  line-height: 14px;
  padding-bottom: 6px;
  position: relative;
  margin-bottom: 8px;
  &::after {
    content: "";
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

export const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  color: ${({ theme }) => theme.color.softBlack};
`
