import React from "react"
import { Link } from "gatsby"
import { styled } from "themes"
import { Headline as StyledHeadline } from "components"

export const Section = styled.section`
  background: #f6f7fa;
`

export const Container = styled.div`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
`

export const Headline = styled((props: any) => <StyledHeadline {...props} />)`
  margin-bottom: 28px;
  margin-left: 12px;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`

export const ListItem = styled.div`
  width: 100%;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const Action = styled.div`
  padding: 48px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ActionButton = styled((props: any) => <Link {...props} />)`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 500;
  padding: 15px 72px;
  cursor: pointer;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`
