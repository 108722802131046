import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 33.586 33.233"
      {...props}
    >
      <g fill="#f6f6f6" stroke="#d3d3d3">
        <rect width={33.586} height={33.233} rx={4} stroke="none" />
        <rect
          x={0.5}
          y={0.5}
          width={32.586}
          height={32.233}
          rx={3.5}
          fill="none"
        />
      </g>
      <path
        d="M25.755 11.632a7.166 7.166 0 01-2.052.573 3.574 3.574 0 001.565-1.977A7.223 7.223 0 0123 11.1a3.53 3.53 0 00-2.632-1.13 3.567 3.567 0 00-3.561 3.564 3.691 3.691 0 00.092.814 10.183 10.183 0 01-7.373-3.722 3.542 3.542 0 001.109 4.744 3.549 3.549 0 01-1.626-.415v.025a3.57 3.57 0 002.869 3.5 3.521 3.521 0 01-1.61.058 3.568 3.568 0 003.336 2.476 7.124 7.124 0 01-4.445 1.526 7.3 7.3 0 01-.851-.05 10.156 10.156 0 005.488 1.6 10.1 10.1 0 0010.183-10.14c0-.158 0-.307-.008-.465a7.133 7.133 0 001.784-1.853z"
        fill="rgba(102,102,102,0.7)"
      />
    </svg>
  )
}

export default SvgComponent
