import React from "react"
import { Headline, Container, Section } from "./style"
import overlay from "../../../images/overlay.png"

const Hero = ({ background, headline }: IHero) => {
  return (
    <Section
      style={{
        background: `url(${overlay}), url(${background}) 0% 0% / cover no-repeat`,
      }}
    >
      <Container>
        <Headline>{headline}</Headline>
      </Container>
    </Section>
  )
}

export default Hero
