import React, { createContext, useContext, useEffect, useState } from "react"
import { throttle } from "lodash"
export const WindowDimensionsCtx = createContext({
  height: 0,
  width: 0,
})

const windowDims = () => ({
  height: typeof window !== `undefined` ? window.innerHeight : 0,
  width: typeof window !== `undefined` ? window.innerWidth : 0,
})

interface IWindowDimensionsProvider {
  children: React.ReactNode
}

const WindowDimensionsProvider = (props: IWindowDimensionsProvider) => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 })
  const [init, setInit] = useState<boolean>(false)
  useEffect(() => {
    const handleResize = () => {
      setDimensions(windowDims())
    }

    const throttledHandleResize = throttle(handleResize, 500)

    window.addEventListener("resize", throttledHandleResize)
    return () => {
      window.removeEventListener("resize", throttledHandleResize)
      if (throttledHandleResize) {
        throttledHandleResize.cancel()
      }
    }
  }, [])

  useEffect(() => {
    if (!init) {
      setInit(true)
      setDimensions({
        height: window.innerHeight || 0,
        width: window.innerWidth || 0,
      })
    }
  }, [init])

  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {props.children}
    </WindowDimensionsCtx.Provider>
  )
}

export default WindowDimensionsProvider

export const useWindowDimensions = () => {
  return useContext(WindowDimensionsCtx)
}
