import { styled } from "themes"

export const Section = styled.section`
  background: #f6f7fa;
  padding: 96px 0;
`

export const Container = styled.div`
  max-width: 955px;
  width: 100%;
  margin: 0 auto;
`

export const Headline = styled.h3`
  font-size: 28px;
  font-weight: bold;
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 12px;
  &::after {
    content: "";
    width: 27px;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

export const Content = styled.div`
  display: flex;
  margin: -24px;
`

export const Description = styled.p`
  flex: 1;
  font-size: 14px;
  padding: 24px;
`

export const AddressList = styled.div`
  flex: 1;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
`

export const AddressListItem = styled.div`
  display: flex;
  flex: 0 0 50%;
  padding: 7px;
`

export const AddressContent = styled.div``

export const Logo = styled.div`
  margin-right: 8px;
  > img {
    width: 25px;
  }
`

export const BranchName = styled.h3`
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  margin-bottom: 4px;
`

export const Address = styled.p`
  font-weight: 14px;
  line-height: 22px;
`
