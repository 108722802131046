import React, { useState, useEffect } from "react"
import { StyledModal, Close } from "./style"

const Modal = (props: any) => {
  const [visible, setVisible] = useState(false)
  const onCancel = () => {
    setVisible(false)
    props.onCancel()
  }

  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  return (
    <StyledModal
      centered
      visible={visible}
      onCancel={onCancel}
      footer={[<Close onClick={onCancel}>Close this window</Close>]}
      width={945}
    >
      {props.children}
    </StyledModal>
  )
}

export default Modal
