import React, { useState } from "react"
import {
  Container,
  Header,
  Content,
  Navbar,
  Logo,
  Contact,
  ContactItem,
  Menu,
  MenuItem,
  MenuLink,
  ActionButton,
  MenuBarIcon,
  MenuExpand,
  ExpandMenu,
  ExpandMenuItem,
} from "./style"
import { Icons, Modal, Search } from "components"
import { isEmpty } from "lodash"

const NavigationBar = ({
  logo,
  phone,
  email,
  menu,
  actions,
  label,
}: INavigationBar) => {
  const [isOpenModal, setOpenModal] = useState(false)
  const [isOpenDrawer, setOpenDrawer] = useState(false)
  const [expandMenuName, setExpandMenuName] = useState("")

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const onExpandMenu = (name: string) => {
    if (expandMenuName === name) {
      setExpandMenuName("")
    } else {
      setExpandMenuName(name)
    }
  }

  const menuExpandContent = (children?: IMenuItem[]) => {
    if (!children) return null
    return (
      <ExpandMenu>
        {children.map(({ url, name }) => (
          <ExpandMenuItem key={name}>
            <MenuLink to={url}>{name}</MenuLink>
          </ExpandMenuItem>
        ))}
      </ExpandMenu>
    )
  }

  return (
    <Container>
      <Header>
        <Content>
          <Logo>
            <img src={logo} alt="Logo" width={135} />
          </Logo>
          <Contact>
            <ContactItem>
              <span>
                <a href={`tel:${phone}`}>{phone}</a>
              </span>
            </ContactItem>
            <ContactItem>
              <span>
                <a href={`mailto:${email}`}>{email}</a>
              </span>
            </ContactItem>
          </Contact>
        </Content>
        <MenuBarIcon onClick={() => setOpenDrawer(true)}>
          <Icons.MenuBar width={20} height={20} />
        </MenuBarIcon>
        <Navbar visible={isOpenDrawer} onClose={() => setOpenDrawer(false)}>
          <Menu>
            {menu.map(({ name, url, children }, k) => (
              <MenuItem key={k}>
                {isEmpty(children) ? (
                  <MenuLink to={url}>{name}</MenuLink>
                ) : (
                  <>
                    <MenuExpand onClick={(e: any) => onExpandMenu(name)}>
                      {name}
                    </MenuExpand>
                    {expandMenuName === name && menuExpandContent(children)}
                  </>
                )}
              </MenuItem>
            ))}
            <MenuItem>
              <MenuLink
                to={"#"}
                onClick={(e: any) => {
                  e.preventDefault()
                  setOpenModal(true)
                }}
              >
                {"Search"}
              </MenuLink>
            </MenuItem>
            {actions.map(({ text, url }, k) => (
              <ActionButton key={k} to={url}>
                {text}
              </ActionButton>
            ))}
          </Menu>
        </Navbar>
      </Header>
      <Modal visible={isOpenModal} onCancel={onCloseModal}>
        <Search />
      </Modal>
    </Container>
  )
}

export default NavigationBar
