import React, { useEffect, useRef, useState } from "react"
import {
  Section,
  Container,
  Headline,
  Description,
  List,
  Column,
  ListItem,
  PaginationWrapper,
  ShareWrapper,
  Detail,
  FilterWrapper,
  LoadingWrapper,
  StyledSelect,
  StyledSelectOption,
} from "./style"
import { Cards, Pagination, SocialShare } from "components"
import queryString from "querystring"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { useCaseStudySearch } from "hooks"
import { Spin } from "antd"

interface IFilterOptionValue {
  sortBy: string
  orderBy: string
}
interface IFilterOption {
  label: string
  value: IFilterOptionValue
}

const filterOptions: IFilterOption[] = [
  {
    label: "Name ASC",
    value: {
      sortBy: "title",
      orderBy: "asc",
    },
  },
  {
    label: "Name DESC",
    value: {
      sortBy: "title",
      orderBy: "desc",
    },
  },
]

const CaseStudiesList = ({ headline, description }: ICaseStudiesList) => {
  const [separatedData, setSeparatedData] = useState({})
  const [numberOfColumns] = useState(2)
  const sectionRef = useRef() as React.MutableRefObject<HTMLElement>
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(8)
  const [skip, setSkip] = useState(0)
  const [sortBy, setSortBy] = useState("")
  const [orderBy, setOrderBy] = useState("")
  const location = useLocation()
  const firstUpdate = useRef(true)

  const {
    data: { data, total },
    loading,
  } = useCaseStudySearch({
    sortBy,
    orderBy,
    skip,
    take: pageSize,
  })

  const scrollUp = () =>
    sectionRef.current.scrollIntoView({ behavior: "smooth" })

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const queryObj: any = {
      page: currentPage,
      take: pageSize,
    }
    sortBy && (queryObj.sortBy = sortBy)
    orderBy && (queryObj.orderBy = orderBy)
    navigate(`${location.pathname}?${queryString.stringify(queryObj)}`)
  }, [sortBy, orderBy, skip, pageSize])

  useEffect(() => {
    const searchParams: any = queryString.parse(
      location.search.replace(/^\?/, "")
    )
    searchParams.sortBy && setSortBy(searchParams.sortBy)
    searchParams.orderBy && setOrderBy(searchParams.orderBy)
    searchParams.page && setCurrentPage(parseInt(searchParams.page))
    searchParams.take && setPageSize(parseInt(searchParams.take))
  }, [location.search])

  useEffect(() => {
    setSkip((currentPage - 1) * pageSize)
  }, [currentPage, pageSize])

  const handleChange = (page: number, pageSize?: number) => {
    scrollUp()
    setCurrentPage(page)
    if (pageSize != null) {
      setPageSize(pageSize)
    }
  }

  useEffect(() => {
    const dataObj = {}
    for (let i = 0; i < numberOfColumns; i++) {
      dataObj[i] = []
    }
    data.forEach((v: any, i: number) => {
      dataObj[i % numberOfColumns].push(v)
    })
    setSeparatedData(dataObj)
  }, [data])

  const onFilterChange = (value: string) => {
    const filterParams: any = queryString.parse(value)
    filterParams.sortBy && setSortBy(filterParams.sortBy)
    filterParams.orderBy && setOrderBy(filterParams.orderBy)
    setCurrentPage(1)
  }

  const defaultFilterValue =
    sortBy && orderBy ? queryString.stringify({ sortBy, orderBy }) : undefined

  const renderListColumns = () =>
    Object.keys(separatedData).map(key => (
      <Column style={{ width: `${100 / numberOfColumns}%` }} key={key}>
        {separatedData[key].map((c: ICaseStudy, k: number) => (
          <ListItem key={k}>
            <Cards.CaseStudy {...c} />
          </ListItem>
        ))}
      </Column>
    ))

  const renderSpinner = () => (
    <LoadingWrapper>
      <Spin />
    </LoadingWrapper>
  )

  return (
    <Section ref={sectionRef}>
      <Container>
        <Headline>{headline}</Headline>
        <Detail>
          <Description>{description}</Description>
          <FilterWrapper>
            <StyledSelect
              style={{ minWidth: 120 }}
              onChange={onFilterChange}
              placeholder={"Filter"}
              value={defaultFilterValue}
            >
              {filterOptions.map(({ label, value }: any) => (
                <StyledSelectOption
                  value={queryString.stringify(value)}
                  key={label}
                >
                  {label}
                </StyledSelectOption>
              ))}
            </StyledSelect>
          </FilterWrapper>
        </Detail>
        <List>{loading ? renderSpinner() : renderListColumns()}</List>
        {total > pageSize && !loading && (
          <PaginationWrapper>
            <Pagination
              current={currentPage}
              total={total}
              pageSize={pageSize}
              onChange={handleChange}
              showSizeChanger={false}
            />
          </PaginationWrapper>
        )}
        <ShareWrapper>
          <SocialShare />
        </ShareWrapper>
      </Container>
    </Section>
  )
}

export default CaseStudiesList
