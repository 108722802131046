import React from "react"
import { Link } from "gatsby"
import { styled } from "themes"
import { Headline as StyledHeadline } from "components"

export const Section = styled.section`
  background: #f6f7fa;
`

export const Container = styled.div`
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 0;
  padding-bottom: 0;
`

export const Headline = styled((props: any) => <StyledHeadline {...props} />)`
  margin-bottom: 32px;
`

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin: -16px;
`

export const ListItem = styled.div`
  flex-basis: 33.33%;
  padding: 16px;
  min-height: 366px;
`

export const Action = styled.div`
  padding: 48px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ActionButton = styled((props: any) => <Link {...props} />)`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 500;
  padding: 15px 72px;
  cursor: pointer;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`
