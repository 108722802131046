import { useState, useEffect, useCallback } from "react"
import queryString from "querystring"
import axios from "axios"

interface IUseCaseStudySearchQuery {
  sortBy?: string
  orderBy?: string
  skip?: number
  take?: number
}
const CASE_STUDY_SEARCH_API = process.env.GATSBY_API_ENDPOINT + "/case-studies"
const initialState = {
  data: [],
  total: 0,
}
export const useCaseStudySearch = ({
  sortBy,
  orderBy,
  skip,
  take,
}: IUseCaseStudySearchQuery) => {
  const [data, setData] = useState<any>(initialState)
  const [query, setQuery] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log({ sortBy, orderBy, skip, take })
    setQuery(queryString.stringify({ sortBy, orderBy, skip, take }))
  }, [sortBy, orderBy, skip, take])

  const fetchData = useCallback(() => {
    const fetchingData = () => {
      setLoading(true)
      axios
        .get(`${CASE_STUDY_SEARCH_API}?${query}`)
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.log(error)
          setData(initialState)
        })
        .finally(() => setLoading(false))
    }
    fetchingData()
  }, [query])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    data,
    loading,
  }
}

export default useCaseStudySearch
