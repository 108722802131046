import React from "react"
import {
  Section,
  Container,
  Headline,
  Content,
  Description,
  AddressList,
  AddressListItem,
  Logo,
  AddressContent,
  BranchName,
  Address,
} from "./style"
import logo from "../../../images/logo-bf.svg"

const BuildingAddress = ({
  headline,
  description,
  venues,
}: IBuildingAddress) => {
  return (
    <Section>
      <Container>
        <Headline>{headline}</Headline>
        <Content>
          <Description>{description}</Description>
          <AddressList>
            {venues.map(({ name, address }, k) => (
              <AddressListItem key={k}>
                <Logo>
                  <img src={logo} alt={"B&F Logo"} />
                </Logo>
                <AddressContent>
                  <BranchName>{name}</BranchName>
                  <Address>{address}</Address>
                </AddressContent>
              </AddressListItem>
            ))}
          </AddressList>
        </Content>
      </Container>
    </Section>
  )
}

export default BuildingAddress
