import React from "react"
import {
  Section,
  Container,
  Content,
  Company,
  Contact,
  Venues,
  Logo,
  Description,
  Headline,
  InfoList,
  Info,
  InfoIcon,
  InfoText,
  Copyright,
  CopyrightText,
  CopyrightItem,
  CopyrightList,
} from "./style"
import { Icons } from "components"
import { Link } from "gatsby"

const Footer = ({
  logo,
  description,
  label,
  phone,
  email,
  venues,
  copyright,
  terms,
}: IFooter) => {
  return (
    <Section>
      <Container>
        <Content>
          <Company>
            <Logo>
              <img src={logo} alt="Logo" />
            </Logo>
            <Description>{description}</Description>
            <Headline>{label.follow}</Headline>
          </Company>
          <Contact>
            <Headline>{label.contact}</Headline>
            <InfoList>
              <Info>
                <InfoIcon>
                  <Icons.MenuBar />
                </InfoIcon>
                <InfoText>
                  <a href={`tel:${phone}`}>{phone}</a>
                </InfoText>
              </Info>
              <Info>
                <InfoIcon>
                  <Icons.MenuBar />
                </InfoIcon>
                <InfoText>
                  <a href={`mailto:${email}`}>{email}</a>
                </InfoText>
              </Info>
            </InfoList>
          </Contact>
          <Venues>
            <Headline>{label.venues}</Headline>
            <InfoList>
              {venues.map(({ address }, k) => (
                <Info key={k}>
                  <InfoIcon>
                    <Icons.Pin />
                  </InfoIcon>
                  <InfoText>{address}</InfoText>
                </Info>
              ))}
            </InfoList>
          </Venues>
        </Content>
        <Copyright>
          <CopyrightText>{copyright}</CopyrightText>
          <CopyrightList>
            {terms.map(({ text, url }, k) => (
              <CopyrightItem key={k}>
                <Link to={url}>{text}</Link>
              </CopyrightItem>
            ))}
          </CopyrightList>
        </Copyright>
      </Container>
    </Section>
  )
}

export default Footer
