import { styled } from "themes"
import leaves from "../../../../images/leaves.png"
import leavesLighter from "../../../../images/leaves-lighter.png"

export const Section = styled.section`
  height: 492px;
`

export const Container = styled(Section)`
  background: url(${leavesLighter}), url(${leaves});
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
`

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 58px;
  transform: translate(0, -50%);
`

export const FirstHeadline = styled.h3`
  color: ${({ theme }) => theme.color.white};
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 0;
`

export const SecondHeadline = styled(FirstHeadline)`
  font-size: 55px;
  line-height: 55px;
  margin-left: 0;
  margin-bottom: 12px;
`

export const Action = styled.div`
  color: #000;
  background: ${({ theme }) => theme.color.white};
  font-size: 18px;
  font-weight: 500;
  max-width: 188px;
  width: 100%;
  outline: none;
  border: 1px solid #707070;
  cursor: pointer;
  padding: 6px 0;
  padding-left: 32px;
`
