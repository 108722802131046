import React from "react"
import {
  // Button,
  Container,
  Content,
  Description,
  Headline,
  Picture,
  Section,
  Action,
  Go,
} from "./style"
import { Buttons } from "components"
import go from "../../../images/go.svg"

const BannerWithPicture = ({
  headline,
  description,
  actions,
  picture,
}: IBannerWithPicture) => {
  return (
    <Section>
      <Container>
        <Content>
          <Headline>{headline}</Headline>
          <Description>{description}</Description>
          <Action>
            {actions.map((a, k) => (
              <Buttons.Primary key={k} to={a.url}>
                {a.button}
              </Buttons.Primary>
            ))}
          </Action>
        </Content>
        <Picture>
          <img src={picture} alt={headline} />
        </Picture>
        <Go>
          <img src={go} alt="Go" />
        </Go>
      </Container>
    </Section>
  )
}

export default BannerWithPicture
