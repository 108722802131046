import React from "react"
import { Breadcrumb } from "antd"
import { styled } from "themes"

export const StyledBreadcrumb = styled((props: any) => (
  <Breadcrumb {...props} />
))`
  &.ant-breadcrumb > span:last-child a {
    color: #fff;
  }
`

export const StyledBreadcrumbItem = styled((props: any) => (
  <Breadcrumb.Item {...props} />
))``
