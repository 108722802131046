import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Icon"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Mask"
            d="M0,7A7,7,0,1,1,14,7c0,5.25-7,13-7,13S0,12.25,0,7Z"
            transform="translate(0.062 0.025)"
            fill={props.color || "currentColor"}
          />
        </clipPath>
      </defs>
      <rect
        id="Base"
        width={props.width || "1em"}
        height={props.height || "1em"}
        fill="none"
      />
      <g
        id="Mask_Group_15"
        data-name="Mask Group 15"
        transform="translate(4.939 1.975)"
        clip-path="url(#clip-path)"
      >
        <g id="Symbols" transform="translate(-0.06 -0.024)">
          <g id="Icon_Location" data-name="Icon/Location">
            <path
              id="Mask-2"
              data-name="Mask"
              d="M11.83,2A6.825,6.825,0,0,0,5,8.83c0,5.122,6.83,12.684,6.83,12.684s6.83-7.562,6.83-12.684A6.825,6.825,0,0,0,11.83,2Zm0,9.269A2.439,2.439,0,1,1,14.269,8.83a2.44,2.44,0,0,1-2.439,2.439Z"
              transform="translate(-5 -2)"
              fill={props.color || "currentColor"}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
