import React, { useEffect, useState } from "react"
import {
  Container,
  Headline,
  SearchBtn,
  SearchInput,
  SearchWrapper,
  SearchContent,
  SearchResult,
  SearchResultContent,
  SearchResultList,
  SearchResultListItem,
  PaginationWrapper,
} from "./style"
import { Empty, Spin } from "antd"
import { useSearch } from "hooks"
import { Cards, Pagination } from "components"

const Search = () => {
  const [query, setQuery] = useState("")
  const [inputVal, setInputVal] = useState("")
  const {
    data,
    isFetching,
    page,
    pageSize,
    setPage,
    setPageSize,
    onSearch,
  } = useSearch()

  useEffect(() => {
    onSearch(query)
  }, [query])

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.trim()) {
      setQuery("")
    }
    setInputVal(e.target.value)
  }

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setQuery(inputVal)
    }
  }

  const onSearchClick = () => {
    setQuery(inputVal)
  }

  const onPaginationChange = (page: number, pageSize?: number) => {
    setPage(page)
    if (pageSize != null) {
      setPageSize(pageSize)
    }
  }

  const renderSearchResultListItem = ({ type, slug, ...rest }: any) => {
    if (type === "news") {
      const props = {
        ...rest,
        slug: `/news/${slug}`,
      }
      return <Cards.ArticleSearchResult {...props} />
    }

    if (type === "case-study") {
      const props = {
        ...rest,
        slug: `/case-studies/${slug}`,
      }
      return <Cards.CaseStudySearchResult {...props} />
    }
    return null
  }

  const renderSearchContent = () => {
    if (isFetching) return <Spin />
    if (data.total === 0) return <Empty />
    return (
      <SearchResultList>
        {data.data.map(({ id, ...props }: any) => (
          <SearchResultListItem key={id}>
            {renderSearchResultListItem(props)}
          </SearchResultListItem>
        ))}
        {data.total > pageSize && (
          <PaginationWrapper>
            <Pagination
              defaultCurrent={page}
              total={data.total}
              defaultPageSize={pageSize}
              onChange={onPaginationChange}
              showSizeChanger={false}
            />
          </PaginationWrapper>
        )}
      </SearchResultList>
    )
  }

  return (
    <Container>
      <Headline>Find everything you need of B&F New Zealand</Headline>
      <SearchWrapper>
        <SearchContent>
          <SearchInput
            placeholder={"What are you searching?"}
            onChange={onInputChange}
            onKeyDown={onInputKeyDown}
          />
          <SearchBtn onClick={onSearchClick}>Search</SearchBtn>
        </SearchContent>
      </SearchWrapper>
      <SearchResult show={!!query}>
        <SearchResultContent>{renderSearchContent()}</SearchResultContent>
      </SearchResult>
    </Container>
  )
}

export default Search
