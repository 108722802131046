import React, { useState } from "react"
import {
  Section,
  Container,
  Content,
  Logo,
  TestimonialBox,
  Avatar,
  Description,
  Name,
  MutedText,
  ActionContent,
  Headline,
  // ActionButton,
  Navigation,
  Next,
  Previous,
  ActionWrapper,
  Action,
} from "./style"
import overlay from "../../../images/overlay.png"
import { isEmpty } from "lodash"
import { Buttons } from "components"

const Testimonial = ({ testimonials }: ITestimonials) => {
  const [active, setActive] = useState(0)

  const onPrev = () => {
    if (active > 0) {
      setActive(prev => prev - 1)
    }
  }

  const onNext = () => {
    if (active < testimonials.length - 1) {
      setActive(prev => prev + 1)
    }
  }

  if (isEmpty(testimonials)) return null

  const activeTestimonial = testimonials[active]

  return (
    <Section>
      <Container
        style={{
          background: `url(${overlay}) 0% 0% / 70% 100% no-repeat, url(${activeTestimonial.background}) 0% 0% / 70% 100% no-repeat`,
          height: "100%",
          transition: "all .2s",
        }}
      >
        <Content>
          <ActionWrapper>
            <Logo>
              <img src={activeTestimonial.logo} />
            </Logo>
            <ActionContent>
              <Headline>{activeTestimonial.headline}</Headline>
              <Action>
                {activeTestimonial.actions.map((a, k) => (
                  <Buttons.Primary key={k} to={a.url}>
                    {a.button}
                  </Buttons.Primary>
                ))}
              </Action>
            </ActionContent>
            <TestimonialBox>
              <Avatar
                src={activeTestimonial.author.avatar}
                alt={activeTestimonial.author.company}
              />
              <Description>{activeTestimonial.text}</Description>
              <Name>{activeTestimonial.author.name}</Name>
              <MutedText>{activeTestimonial.author.company}</MutedText>
            </TestimonialBox>
          </ActionWrapper>
          <Navigation>
            <Previous active={active > 0} onClick={onPrev}>
              Prev
            </Previous>
            <Next active={active < testimonials.length - 1} onClick={onNext}>
              Next
            </Next>
          </Navigation>
        </Content>
      </Container>
    </Section>
  )
}

Testimonial.defaultProps = {
  testimonials: [],
}

export default Testimonial
