import { Cards } from "components"
import React from "react"
import { Container, Section, Headline, List, ListItem, Action } from "./style"

import { Buttons } from "components"

const RelatedArticles = ({ headline, articles, actions }: IRelatedArticles) => {
  return (
    <Section>
      <Container>
        <Headline>{headline}</Headline>
        <List>
          {articles.map((article, k) => (
            <ListItem key={k}>
              <Cards.Article {...article} />
            </ListItem>
          ))}
        </List>
      </Container>
      <Action>
        {actions.map((a, k) => (
          <Buttons.Primary key={k} to={a.url}>
            {a.text}
          </Buttons.Primary>
        ))}
      </Action>
    </Section>
  )
}

RelatedArticles.defaultProps = {
  headline: "",
  articles: [],
}

export default RelatedArticles
