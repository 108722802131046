import React from "react"
import { styled } from "themes"
import { Headline as StyledHeadline } from "components"
import { Markdown } from "components"
import { ReactMarkdownOptions } from "react-markdown"
import { Popover, Button, Select } from "antd"
const { Option } = Select

export const Section = styled.section``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Headline = styled((props: any) => <StyledHeadline {...props} />)``

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const Description = styled((props: ReactMarkdownOptions) => (
  <Markdown {...props} />
))`
  padding: 12px 0;
  margin-bottom: 0;
  > p {
    font-size: 12px;
  }
`

export const FilterWrapper = styled.div``

export const StyledButton = styled((props: any) => <Button {...props} />)`
  && {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledPopover = styled((props: any) => (
  <Popover placement="bottom" {...props} />
))``

export const List = styled.div`
  display: flex;
  margin: 0 -6px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 6px;
`

export const ListItem = styled.div`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
    flex: 1;
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 32px;
`

export const ShareWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StyledSelect = styled((props: any) => <Select {...props} />)`
  && {
    &.ant-select-single.ant-select-open {
      .ant-select-selection-item {
        color: ${({ theme }) => theme.color.white};
      }
    }
    .ant-select-selector {
      background: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.white};
      .ant-select-selection-placeholder {
        color: ${({ theme }) => theme.color.white};
      }
    }
    .ant-select-arrow {
      color: ${({ theme }) => theme.color.white};
    }
  }
`

export const StyledSelectOption = styled((props: any) => (
  <Option {...props} />
))``
