import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M10.384 11.538a1.154 1.154 0 010-2.308h6.924a1.154 1.154 0 110 2.308zm-9.23-4.615a1.154 1.154 0 010-2.308h16.154a1.154 1.154 0 110 2.308zm0-4.615a1.154 1.154 0 010-2.308h6.923a1.154 1.154 0 110 2.308z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g data-name="Group 6298">
        <path
          data-name="L1 Icon background"
          fill="rgba(0,0,0,0)"
          d="M0 0h20v20H0z"
        />
        <g
          data-name="Group 6297"
          transform="translate(.769 4.615)"
          clipPath="url(#prefix__a)"
        >
          <g data-name="\u21B3 \uD83C\uDFA8Color">
            <path
              data-name="\u21B3 \uD83C\uDFA8Color background"
              fill="rgba(0,0,0,0)"
              d="M-.769-4.615h20v20h-20z"
            />
            <path
              fill={props.color || "currentColor"}
              d="M-.769-4.615h20v20h-20z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
