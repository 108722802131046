import React from "react"
import { styled } from "themes"
import { Link } from "gatsby"
import { Popover } from "antd"

export const Container = styled.section`
  /* background-color: rgba(0, 0, 0, 0.5); */
  min-height: 115px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
`

export const Header = styled.header`
  color: ${({ theme }) => theme.color.white};
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 10px;
`

export const Logo = styled((props: any) => <Link to={"/"} {...props} />)`
  margin-right: auto;
`

export const Contact = styled.ul`
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
`

export const ContactItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  > label {
    font-weight: bold;
  }
  > span {
    > a {
      color: ${({ theme }) => theme.color.white};
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export const Navbar = styled.nav`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  margin-bottom: -45px;
  height: 45px;
`

export const Menu = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  background: #000;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
  padding-left: 68px;
  height: 100%;
`

export const MenuItem = styled.li`
  display: inline-block;
  padding: 0 16px;
  text-transform: uppercase;
  position: relative;
`

export const MenuContent = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

export const MenuContentItem = styled.li`
  text-align: center;
  border-bottom: 2px solid #5ac167;
  padding: 12px 0;
  &:last-child {
    border-bottom: none;
  }
`

export const PopoverStyled = styled((props: any) => (
  <Popover placement="bottom" {...props} />
))``

export const PopoverButton = styled.button`
  color: ${({ theme }) => theme.color.white};
  background: none;
  border: none;
  outline: none;
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
  &::after {
    content: "";
    width: 0;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 16px;
    transition: width 0.2s;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
    &::after {
      width: 16px;
    }
  }
`

export const MenuLink = styled((props: any) => <Link {...props} />)`
  color: ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  &::after {
    content: "";
    width: 0;
    height: 2px;
    background: ${({ theme }) => theme.color.primary};
    position: absolute;
    bottom: 0;
    left: 16px;
    transition: width 0.2s;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
    &::after {
      width: 16px;
    }
  }
`

export const NavSearch = styled.button`
  background: #292929;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
`

export const ActionButton = styled((props: any) => <Link {...props} />)`
  padding: 0 34px;
  border: 0;
  outline: 0;
  background: ${({ theme }) => theme.color.primary};
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.white};
  border-right: 1px solid #e4e2e2;
  &:last-child {
    border-radius: 0 4px 4px 0;
    border-right: none;
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`
