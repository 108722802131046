import React from "react"
import Desktop from "./Desktop"
import { ResponsiveLayout } from "components"

const RelatedCaseStudies = (props: IRelatedCaseStudies) => {
  return (
    <ResponsiveLayout
      mobileView={<Desktop {...props} />}
      desktopView={<Desktop {...props} />}
    />
  )
}

export default RelatedCaseStudies
