import React from "react"
import {
  Action,
  Container,
  Content,
  FirstHeadline,
  SecondHeadline,
} from "./style"

import singleLeave01 from "../../../../images/single-leave-01.png"
import singleLeave02 from "../../../../images/single-leave-02.png"
import singleLeave03 from "../../../../images/single-leave-03.png"

const FirstSlide = () => {
  return (
    <Container>
      <Content>
        <FirstHeadline>Our planet</FirstHeadline>
        <SecondHeadline>Our Home</SecondHeadline>
        <Action>Find out more</Action>
      </Content>
      <img
        src={singleLeave01}
        style={{
          position: "absolute",
          left: "-38px",
          bottom: "-50px",
        }}
      />
      <img
        src={singleLeave02}
        style={{
          position: "absolute",
          left: "35%",
          bottom: 0,
          transform: "translate(-17.5%, 0)",
        }}
      />
      <img
        src={singleLeave03}
        style={{
          position: "absolute",
          right: 0,
        }}
      />
    </Container>
  )
}

export default FirstSlide
