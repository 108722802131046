import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 33.586 33.233"
      {...props}
    >
      <g fill="#f6f6f6" stroke="#d3d3d3">
        <rect width={33.586} height={33.233} rx={4} stroke="none" />
        <rect
          x={0.5}
          y={0.5}
          width={32.586}
          height={32.233}
          rx={3.5}
          fill="none"
        />
      </g>
      <path
        d="M24.094 12.047a.415.415 0 01-.415.415h-1.662a.415.415 0 01-.415-.415v-1.662a.415.415 0 01.415-.415h1.662a.415.415 0 01.415.415M11.216 23.263a.415.415 0 01-.415-.415v-7.063h1.738a4.545 4.545 0 00-.075.831 4.985 4.985 0 109.97 0 5.419 5.419 0 00-.075-.831h1.736v7.062a.415.415 0 01-.415.415m-6.231-9.97a3.323 3.323 0 11-3.323 3.323 3.323 3.323 0 013.323-3.323m6.645-4.984H10.801A1.656 1.656 0 009.139 9.97v13.293a1.662 1.662 0 001.662 1.662h13.293a1.662 1.662 0 001.662-1.662V9.97a1.661 1.661 0 00-1.662-1.662z"
        fill="rgba(102,102,102,0.7)"
      />
    </svg>
  )
}

export default SvgComponent
