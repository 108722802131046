import React from "react"
import { Section, Text, List, ListItem } from "./style"
import { Icons } from "components"

const SocialShare = () => {
  return (
    <Section>
      <Text>
        <strong>¿DO YOU LIKE THIS?</strong> SPREAD THE VOICE
      </Text>
      <List>
        <ListItem>
          <Icons.Facebook />
        </ListItem>
        <ListItem>
          <Icons.Twitter />
        </ListItem>
        <ListItem>
          <Icons.Instagram />
        </ListItem>
      </List>
    </Section>
  )
}

export default SocialShare
