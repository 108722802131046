import { Cards } from "components"
import React from "react"
import { Container, Section, Headline, List, ListItem } from "./style"

const RelatedCaseStudies = ({ headline, caseStudies }: IRelatedCaseStudies) => {
  return (
    <Section>
      <Container>
        <Headline>{headline}</Headline>
        <List>
          {caseStudies.map((c, k) => (
            <ListItem key={k}>
              <Cards.CaseStudy {...c} />
            </ListItem>
          ))}
        </List>
      </Container>
    </Section>
  )
}

RelatedCaseStudies.defaultProps = {
  headline: "",
  caseStudies: [],
}

export default RelatedCaseStudies
