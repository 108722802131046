import React from "react"
import {
  Container,
  PublishDate,
  Section,
  Thumbnail,
  Title,
  CardBody,
  GatsbyImageStyled,
} from "./style"

import playButton from "../../../../images/play-button.svg"
import { displayDate } from "helpers"

const Article = ({
  thumbnail,
  date,
  title,
  description,
  slug,
  hasVideo,
}: IArticle) => {
  return (
    <Section>
      <Container to={slug}>
        <Thumbnail>
          {hasVideo && (
            <img src={playButton} alt="Play button" className="play-button" />
          )}
          <GatsbyImageStyled image={thumbnail} alt={title} />
        </Thumbnail>
        <CardBody>
          <PublishDate>{displayDate(date)}</PublishDate>
          <Title>{title}</Title>
        </CardBody>
      </Container>
    </Section>
  )
}

export default Article
