import React from "react"
import {
  Section,
  Container,
  Logo,
  Form,
  Headline,
  MutedText,
  FormList,
  FormItem,
  Label,
  Input,
  Submit,
  ErrorMessage,
} from "./style"
import logo from "../../../../images/logo.svg"
import { useForm, Controller } from "react-hook-form"

interface IFormInput {
  email: string
}

const ResetPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data: IFormInput) => console.log(data)

  return (
    <Section>
      <Container>
        <Logo>
          <img src={logo} alt="BF Logo" />
        </Logo>
        <Form onSubmit={handleSubmit(onSubmit)} id="login">
          <Headline>Forgot Password</Headline>
          <MutedText>Enter your registered email account</MutedText>
          <FormList>
            <FormItem>
              <Label>Email</Label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <Input type="email" {...field} />}
                rules={{
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Invalid email",
                  },
                }}
              />
              {errors.email && (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              )}
            </FormItem>
          </FormList>
          <Submit
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
          >
            Send Password
          </Submit>
        </Form>
      </Container>
    </Section>
  )
}

export default ResetPassword
