import React from "react"
import { Button } from "./style"

const Primary = ({ children, ...rest }: any) => {
  return (
    <Button type={"primary"} size={"large"} {...rest}>
      {children}
    </Button>
  )
}

export default Primary
