import React from "react"
import Mobile from "./Mobile"
import { ResponsiveLayout } from "components"

const Drawer = (props: any) => {
  return (
    <ResponsiveLayout
      mobileView={<Mobile {...props} />}
      desktopView={<Mobile {...props} />}
    />
  )
}

export default Drawer
