import Article from "./Article"
import HorizontalArticle from "./HorizontalArticle"
import CaseStudy from "./CaseStudy"
import ArticleSearchResult from "./ArticleSearchResult"
import CaseStudySearchResult from "./CaseStudySearchResult"

export default {
  Article,
  CaseStudy,
  HorizontalArticle,
  ArticleSearchResult,
  CaseStudySearchResult,
}
